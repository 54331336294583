import React from "react"
import '../../styles/components/dropdown.css'

const Dropdown = () => {
  function clickHandler (event) {
    document.getElementById('header-expand').classList.toggle('expanded');
    document.getElementById('header-expand-control').classList.toggle('active');
  }
  return (
    <div className='dropdown' onClick={clickHandler}>
      <div
        id='header-expand-control'
        className='hamburger hamburger--squeeze js-hamburger'
      >
        <div className='hamburger__box'>
          <div className='hamburger__inner'>
          </div>
        </div>
      </div>
    </div>)
}

export default Dropdown;
