import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header/header";
import "../styles/layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <head>
        <link
          rel="stylesheet"
          type='style'
          href="https://fonts.googleapis.com/css?family=Lusitana"
        />
      </head>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main
        style={{
          textAlign: `center`,
        }}
      >{children}</main>
      <footer>
        © {new Date().getFullYear()}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
