import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/utilities/seo"

import '../styles/pages/404.css'

const NotFoundPage = () => (
  <Layout>
    <div className='fourOhFour'>
      <SEO title="404: Not found" />
      <h1 className='main-title'>Page not found</h1>
      <p>Unfortunately the page you are looking for doesn't exist.</p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
