import PropTypes from "prop-types"
import React from "react"

import HeaderLink from './header-link';
import Dropdown from './dropdown';
import leafSwirl from '../../images/white-swirl.svg';

import '../../styles/components/header.css';

const Header = () => (
  <header
    className='header-main'
  >
    <div className='header-main__horizontal'>
      <div className='header__link-wrapper'>
        <h4 className='header__caption'>Dr Eleanor Chan   Historian of Art and Music</h4>
        <h1 style={{ margin: 0 }}>
          <HeaderLink {...{ link: '' }}>
            <img id='header-swirl' className='icon' alt='swirl with leaves logo' src={leafSwirl} />
          </HeaderLink>
          <HeaderLink {...{text:'About', link:'about'}}/>
          <HeaderLink {...{text:'Gallery', link:'gallery'}}/>
          {/* <HeaderLink {...{text:'Blog', link:'blog'}}/> */}
          <HeaderLink {...{text:'Publications', link:'publications'}}/>
          <HeaderLink {...{text:'Contact', link:'contact'}}/>
        </h1>
      </div>
    </div>
    <div className='header-main__vertical'>
      <div className='header__link-wrapper'>
        <Dropdown/>
        <h1 id='header-expand' style={{ margin: 0 }}>
          <HeaderLink {...{text:'Home', link:''}}/>
          <HeaderLink {...{text:'About', link:'about'}}/>
          <HeaderLink {...{text:'Gallery', link:'gallery'}}/>
          {/* <HeaderLink {...{text:'Blog', link:'blog'}}/> */}
          <HeaderLink {...{text:'Publications', link:'publications'}}/>
          <HeaderLink {...{text:'Contact', link:'contact'}}/>
        </h1>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
